<template>
    <div
        class="sticky top-[-2px] z-[51] hidden w-full flex-col bg-[#F7D936] lg:flex"
    >
        <div class="mx-auto my-0 w-full max-w-[1100px]">
            <ul class="flex justify-between">
                <li
                    v-for="item in mainNavbar"
                    :key="item.id"
                    class="relative flex h-full cursor-pointer items-center p-5 text-sm transition-all hover:text-[#B01E46]"
                    @mouseover="showDropdown(true, item.id)"
                    @mouseleave="showDropdown(false)"
                >
                    <span
                        :class="[
                            'flex items-center text-center text-sm font-normal',
                            {
                                ['router-link-active router-link-exact-active']:
                                    determineActiveRoute(item.id),
                                active: determineActiveRoute(item.id),
                            },
                        ]"
                        @click="determineUrl(item)"
                    >
                        {{ $t(item.i18_key) }}
                    </span>
                    <img
                        v-if="item?.isHot"
                        class="absolute -right-1 top-2 h-auto w-6"
                        alt="fire icon"
                        :src="`${staticUrl}/assets/icons/fire.gif`"
                        loading="lazy"
                    />
                    <img
                        v-if="item?.isLive"
                        class="absolute -right-1 top-3 h-auto w-6"
                        alt="live icon"
                        :src="`${staticUrl}/assets/icons/live.gif`"
                        loading="lazy"
                    />
                </li>
            </ul>
        </div>
        <!-- isShow && MENU_LIST[keySubMenu] !== undefined -->
        <Transition>
            <div
                v-if="isShow && MENU_LIST[keySubMenu] !== undefined"
                class="absolute left-0 right-0 top-[60px] bg-[url('/assets/img/menu/bg.png')] pb-[35px] pt-[36px]"
                @mouseover="showDropdown(true)"
                @mouseleave="showDropdown(false)"
            >
                <div
                    v-if="keySubMenu !== 'sport'"
                    class="mx-auto flex flex-wrap justify-center gap-[40px]"
                >
                    <div
                        v-for="(item, index) in MENU_LIST[keySubMenu]"
                        :key="index"
                        class="relative aspect-[90/114] cursor-pointer transition-all ease-in-out hover:-translate-y-[3px]"
                        @click="redirect(item)"
                    >
                        <img
                            v-if="item?.isHot"
                            class="absolute left-0 top-0 h-auto w-10"
                            :src="`${staticUrl}/assets/icons/fire.gif`"
                            alt="fire icon"
                            loading="lazy"
                        />
                        <img
                            v-if="item?.isNew"
                            class="absolute left-0 top-0 h-auto w-10"
                            :src="`${staticUrl}/assets/icons/star.gif`"
                            alt="star icon"
                            loading="lazy"
                        />
                        <img
                            class="h-[171px] w-[135px]"
                            :src="`/assets/img/menu/${item.name}.png`"
                            :alt="item.name"
                        />
                    </div>
                </div>
                <div v-else class="flex justify-center gap-[80px]">
                    <div>
                        <div class="text-[16px] font-bold text-white">
                            Sportbooks
                        </div>
                        <div class="flex gap-[40px]">
                            <div
                                v-for="item in MENU_LIST[keySubMenu].sportBook"
                                :key="item.name"
                                class="relative aspect-[90/114] cursor-pointer transition-all ease-in-out hover:-translate-y-[3px]"
                                @click="redirect(item)"
                            >
                                <img
                                    v-if="item?.isHot"
                                    class="absolute left-0 top-0 h-auto w-10"
                                    :src="`${staticUrl}/assets/icons/fire.gif`"
                                    alt="fire icon"
                                    loading="lazy"
                                />
                                <img
                                    v-if="item?.isNew"
                                    class="absolute left-0 top-0 h-auto w-10"
                                    :src="`${staticUrl}/assets/icons/star.gif`"
                                    alt="star icon"
                                    loading="lazy"
                                />
                                <img
                                    class="h-[171px] w-[135px]"
                                    :src="`/assets/img/menu/${item.name}.png`"
                                    :alt="item.name"
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="text-[16px] font-bold text-white">
                            Virtual Sports
                        </div>
                        <div class="flex gap-[40px]">
                            <div
                                v-for="item in MENU_LIST[keySubMenu]
                                    .virtualSports"
                                :key="item.name"
                                class="relative aspect-[90/114] cursor-pointer transition-all ease-in-out hover:-translate-y-[3px]"
                                @click="redirect(item)"
                            >
                                <img
                                    v-if="item?.isHot"
                                    class="absolute left-0 top-0 h-auto w-10"
                                    :src="`${staticUrl}/assets/icons/fire.gif`"
                                    alt="fire icon"
                                    loading="lazy"
                                />
                                <img
                                    v-if="item?.isNew"
                                    class="absolute left-0 top-0 h-auto w-10"
                                    :src="`${staticUrl}/assets/icons/star.gif`"
                                    alt="star icon"
                                    loading="lazy"
                                />
                                <img
                                    class="h-[171px] w-[135px]"
                                    :src="`/assets/img/menu/${item.name}.png`"
                                    :alt="item.name"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>
<script setup>
import { MENU_LIST } from '@/constants/menu.js'
import {
    useGamesStore,
    useAlertStore,
    useModalStore,
    useUserStore,
} from '~/stores'
const staticUrl = useRuntimeConfig().public.staticUrl
const localePath = useLocalePath()
const route = useRoute()
const router = useRouter()
const gameStoreInstance = useGamesStore()
const { getGameUrl } = gameStoreInstance

const alertStore = useAlertStore()

const keySubMenu = ref(null)

const showDropdown = useDebounceBigg((payload, key) => {
    if (key) {
        keySubMenu.value = key
    }
    isShow.value = payload
}, 100)

const determineUrl = (item) => {
    if (item.id === 'cock_fight') {
        redirect(MENU_LIST.cock_fight[0])
    } else {
        navigateTo(localePath(item.url))
    }
}

const isShow = ref(false)

const determineActiveRoute = (id) => {
    const routeObjectById = {
        sport: [
            '/sports',
            '/osports',
            '/ksports',
            '/saba-sports',
            '/ssports',
            '/tsports',
            '/in-play',
            '/btisports',
        ]?.includes(route.path),
        games: [
            '/games/all',
            '/games/instants',
            '/games/ingame',
            '/games/tables',
        ].includes(route.path),
        logo: ['/lottery']?.includes(route.path),
        esports: ['/e-sports', '/im-sports', '/saba-sports']?.includes(
            route.path
        ),
        sports: ['/osports', '/ssports', '/tsports', '/ksports']?.includes(
            route.path
        ),
        lotto: ['/lottery'].includes(route.path),
        live_casino:
            route.name === 'casino-type' || ['/casino'].includes(route.path),
        fishing: ['/games/fishing'].includes(route.path),
        table_game: ['/games/tables'].includes(route.path),
        slots: ['/games/slots', '/en/games/slots'].includes(route.path),
        quick_game: ['/games/instants'].includes(route.path),
        lottery: ['/games/lottery'].includes(route.path),
    }
    if (!routeObjectById?.[id]) {
        return false
    }
    return routeObjectById[id]
}

const handleGetGameUrl = async (toSend) => {
    try {
        const { data } = await getGameUrl(toSend)
        if (data.value?.status === 'OK') {
            // TODO:handle mobile version url_mobile
            const { url } = data.value.data
            if (import.meta.client) {
                setTimeout(() => window.open(url, '_blank'))
            }
        }
    } catch (error) {
        alertStore.showMessageError(error)
    }
}

const redirect = (item) => {
    const useUserStoreInstance = useUserStore()
    if (item.key === 'digmaan') {
        const toSend = {
            partnerProvider: 'cockfight',
            partnerGameId: 'lobby',
        }
        handleGetGameUrl(toSend)
        return
    }

    if (item.key === 'im_esports') {
        const toSend = {
            partnerProvider: 'im',
            partnerGameId: 'esport',
        }
        handleGetGameUrl(toSend)
        return
    }
    // if (item.key === 'ibc') {
    //     loadSport('/athena/tableGameUrl')
    //     return
    // }
    if (item.link === router.currentRoute.value.fullPath) {
        isShow.value = false
    }
    if (item.isRequireLogin && !useUserStoreInstance.isLoggedIn) {
        const useModalStoreInstance = useModalStore()
        useModalStoreInstance.showLoginModal = true
        return
    }
    router.push(localePath(item.url))
    showDropdown(false)
}
const mainNavbar = ref([
    {
        id: 'sport',
        url: '/sports',
        title: 'Sports',
        i18_key: 'header.nav_menu.sports',
        isHot: true,
    },
    {
        id: 'slots',
        url: '/games/slots?partner=',
        title: 'Slots',
        i18_key: 'header.nav_menu.slot',
    },
    {
        id: 'live_casino',
        url: '/casino/all?partner=',
        title: 'Casino',
        i18_key: 'header.nav_menu.casino',
        isLive: true,
    },
    {
        id: 'fishing',
        url: '/games/fishing?partner=',
        title: 'Fishing',
        i18_key: 'header.nav_menu.fishing',
    },
    {
        id: 'table_game',
        url: '/games/tables?partner=',
        title: 'Table Games',
        i18_key: 'header.nav_menu.tables',
    },

    {
        id: 'lottery',
        url: '/games/lottery?partner=',
        title: 'lottery',
        i18_key: 'header.nav_menu.lottery',
    },
    {
        id: 'quick_game',
        url: '/games/instants?partner=',
        title: 'Quick Games',
        i18_key: 'header.nav_menu.quick_game',
    },
    {
        id: 'cock_fight',
        title: 'Cockfight',
        i18_key: 'header.nav_menu.cockfight',
    },
    {
        id: 'esports',
        url: '/e-sports',
        title: 'E-Sports',
        i18_key: 'header.nav_menu.e-sports',
    },
])
// import { useSportsStore } from '~/stores'
// const useSportsStoreInstance = useSportsStore()
// const { getSportUrl } = useSportsStoreInstance

// const loadSport = async (sportApi) => {
//     try {
//         const { data } = await getSportUrl(sportApi)
//         if (data?.status === 'OK') {
//             window.open(data?.data?.url, '_blank')
//         }
//     } catch (error) {}
// }
</script>
<style lang="scss" scoped>
.active,
.router-link-active {
    position: relative;
    color: #b01e46;
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-thickness: 2px;
    font-weight: 600;
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
