<template>
    <div>
        <Veeform
            class="space-y-[25px]"
            :validation-schema="resetPasswordByOtpSchema"
            @submit="handleResetPasswordByOtp"
        >
            <CommonPasswordInput
                id="password"
                type="password"
                name="password"
                label="New password"
                placeholder="Enter from 6 characters"
            />
            <CommonPasswordInput
                id="confirmPassword"
                type="password"
                name="confirmPassword"
                label="Confirm Password"
                placeholder="Confirm new password"
            />
            <CommonButton :loading="isSubmitting">
                {{ $t('modal.forgot_password.create_new_password') }}
            </CommonButton>
        </Veeform>
    </div>
</template>
<script setup>
import { Form as Veeform } from 'vee-validate'
import { resetPasswordByOtpSchema } from '~/forms/resetPasswordByOtpSchema.schema'
import { useResetPassStore, useAlertStore } from '~/stores'
import { storeToRefs } from 'pinia'

const emit = defineEmits(['next'])

const useResetPassStoreInstance = useResetPassStore()
const { submittedUsername, submittedEmail, submittedOTP } = storeToRefs(
    useResetPassStoreInstance
)
const { resetPasswordByOtp } = useResetPassStoreInstance
const isSubmitting = ref(false)
const handleResetPasswordByOtp = async (payload) => {
    try {
        isSubmitting.value = true
        const toSend = {
            username: submittedUsername.value,
            email: submittedEmail.value,
            otp: submittedOTP.otp,
            password: payload.password,
            confirmPassword: payload.confirmPassword,
        }
        const { data } = await resetPasswordByOtp(toSend)
        if (data.value.status === 'OK') {
            emit('next')
        } else {
            throw data
        }
    } catch (error) {
        useAlertStore().showMessageError(error)
    } finally {
        isSubmitting.value = false
    }
}
</script>
